import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="main-heading text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is the Asset Tokenization?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">The process of converting real-world assets like artworks, music, real estate, stock, and any kind of valuables into digital tokens is called Asset Tokenization. Supported by a blockchain network the digital assets can be effortlessly traded, divided, and can be transferred attracting a wide range of investors.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                What is asset tokenization development?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Asset Tokenization development is the process of converting real-world assets into digital tokens. This process allows users to enjoy liquidity with transparency allowing fractional ownership and easy ownership transferability of assets in the platform.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                What is the actual cost of developing an asset tokenization platform?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The asset tokenization development would cost you around <b>$8,000 to $15,000</b>.  However, this cost might change depending on various factors like the client’s needs and requirements. The influencing factors include the regulatory compliance requirements, security considerations, and functionality of the tokenization platform.
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                How long does it take to develop an Asset Tokenization Platform?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The approximate time duration for an Asset Tokenization Development will be around 10 days to 3 months. Typically, this might vary depending on the complexity and the chosen method of the development process of the project. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                Do you offer real estate tokenization platform development?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Yes, Coinsclone offers various successful crypto-related services including Real estate tokenization platform development. Using our crypto developers and the clone scripts or white label solution we develop your Real Estate Tokenization platform development integrating robust security mechanisms and required smart contracts.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle"  eventKey="5">
                How asset tokenization development can be used for fundraising?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">Asset Tokenization development can be handy in various forms of fundraising like utilizing the tokens in (Initial Coin Offerings) ICOs,( Security Token Offerings) STOs, and crowdfunding. Additionally, these tokens can also be used within the DeFi ecosystem allowing fractional ownership of the digital assets.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '6' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('6')} className="panel-toggle"  eventKey="6">
                Which company provides the best real estate tokenization development service?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="6">
                <div className="panel-body card-body">Coinsclone is at the forefront of the companies excelling in real estate tokenization development acting as a one-stop solution from ideation to deployment you can rely on. With a proven and solid track record for nearly a decade, Coinsclone’s developers provide top-notch development services.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>
  )
}

export default FaqSection