import React from "react"

class Benificial extends React.Component {

  render() {
    return (

      <section className="benifor tokenize pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Token Standards </span> We Work For Asset Tokenization Platform Development
            </h2>
            <p className="text-center">
            Coinsclone’s Asset Tokenization Platform Development allows you an option to pick a wide range of popular token standards. The token standards determine the functionality and structure of the created token and platform.
            </p>
            <div className="d-lg-flex flex-wrap add-one">
              <div className="square" >
                <h3>ERC20</h3>
                <p className="pharagraph">
                Adopt this ERC20 token, one of the highly adopted token standards on the Ethereum blockchain. This is a fungible token standard, meaning all the tokens are similar and interchangeable. They are interpreted and compatible with many services.
                </p>
              </div>
              <div className="square" >
                <h3>ERC721</h3>
                <p className="pharagraph">
                If you are looking to develop a non-fungible token to create distinguishable and unique tokens then ERC721 is the most suitable. They represent the ownership of virtual assets like gaming characters, digital art, and other virtual collectibles.
                </p>
              </div>
              <div className="square" >
                <h3>ERC1155</h3>
                <p className="pharagraph">
                ERC 1155 is a type of hybrid token standard that partially supports both fungible and non-fungible tokens. Incorporate these token standards to back up the integration of both properties and characteristics when creating unique digital collectibles.
                </p>
              </div>
              <div className="square" >
                <h3>ERC1404</h3>
                <p className="pharagraph">
                ERC 1404 is the advanced extension of the ERC20 token standard but with enhanced transfer restrictions. This enables the issuance and transfer of the security tokens promising legal and regulatory compliances in the Ethereum blockchain.
                </p>
              </div>
              <div className="square" >
                <h3>ERC1462</h3>
                <p className="pharagraph">
                Being recently launched on the Ethereum blockchain, this ERC1462 token standard regulates legal enforceability and security regulatory compliances. Utilize this token standard in seamlessly issuing and trading securities within the blockchain network.
                </p>
              </div>
              <div className="square" >
                <h3>ERC3643</h3>
                <p className="pharagraph">
                This is an extension of ERC20 tokens that interoperate with any ERC 20-supported applications. Benefit with this security-centric standard that allows transfer restrictions and, the creation of various types of tokens.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Benificial
