
import React from 'react'



class WhatMake extends React.Component {


  render() {

    return (
      <section className="what-make tokenize pt-100 mb-0">   
        <div className='gray-bg'>
          <div className="container">
            <h2 className="main-heading text-center">Asset Tokenization Development In <span className='bluecolor'>Various Industries</span></h2>
            <p className='text-center'>Asset Tokenization development transcends the reality of owning up the assets by making an impact across a wide array of industries. This goes beyond mere ownership operations by connecting and expanding the digital realm executing a significant influence in each industrial space.</p>
              <div className='d-flex flex-wrap justify-content-center ind-std'>
                  <p>Real Estate</p>
                  <p>Digital Artworks</p>
                  <p>Gaming Collectibles</p>
                  <p>Health Care</p>
                  <p>Financial Services</p>
                  <span></span>
                  <p>Agriculture</p>
                  <p>Logistics & Supply Chain</p>
                  <p>Intangible Assets</p>
                  <p>Energy & Renewable Resources</p>
                  <p>Luxury Goods</p>
                  <p>Stocks & Bonds</p>
                  <p>Entertainment Industry</p>
              </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhatMake