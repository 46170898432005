import React from 'react'



class Whatis extends React.Component {

  render() {

    return (
      <section className="whatis pt-0">                                                                                             
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center">Asset Tokenization Platform Development - <span className='bluecolor'>An Overview </span></h2>
            <div className="text-center">
              <p className="pharagraph text-center">Coinsclone is a leading Asset tokenization platform development company that offers high-end secured tech development services. Asset tokenization platform development is the process of building a platform that connects digital tokens with interested investors. These platforms leverage blockchain technology with a decentralization landscape. Our expert developers specialize in crafting the secured Asset tokenization platform to efficiently trade your tokenized digital assets.
              </p>
              <p className="pharagraph text-center">With blockchain’s enhanced tokenization process, create any valuables like virtual artworks, gaming characters, stocks, and other digital collectibles. From real estate and fine art to intellectual property and commodities, our comprehensive platform caters to diverse asset classes, providing customizable solutions tailored to meet the unique needs of each client.
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis