import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>Asset Tokenization Platform</span> Development Company</h1>
                <p className="pharagraph"><b>Let's trade the untradable </b></p>
                <p className='pharagraph mb-0'>Join hands with Coinsclone a leading Asset Tokenization Platform development company, and seize the opportunity today to build a futuristic innovation tomorrow. Ignite your business ideas with our top-notch Asset tokenization development services fortified with robust security mechanisms and excellence.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/asset-token/asset-tokanization-platform-development-company.webp"
                alt="Asset Tokenization Platform Development Company"
                className='d-none d-md-block float-end'
                width={586}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection